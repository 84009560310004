import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from '../../../../authentication-lib/src/lib/services/login.service';
import { Subject } from 'rxjs';
import { takeUntil, filter, switchMap, take, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { TokenService } from '../../../../authentication-lib/src/lib/services/token.service';
import { TeilnehmerService } from '../../services/teilnehmer.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  formGroup: FormGroup;
  destroyed$: Subject<any> = new Subject<any>();

  constructor(
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private router: Router,
    private httpClient: HttpClient,
    private teilnehmerService: TeilnehmerService
  ) {
    this.formGroup = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      code: []
    });
  }

  ngOnInit(): void {
    this.handleAuthentication();
  }

  handleAuthentication(): void {
    this.loginService
      .getAuthState()
      .pipe(
        takeUntil(this.destroyed$),
        tap(state => console.log('authstate: ', state)),
        filter(authenticated => !!authenticated),
        switchMap(() => this.teilnehmerService.requestTeilnehmernummer())
      )
      .subscribe(() => {
        this.router.navigate(['/']);
      });
  }

  onSubmit(): void {
    this.loginService.loginWithUsernameAndPassword(this.formGroup.value);
   }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }
}
