import { Component, OnInit } from '@angular/core';

export interface EventItem {
  title: string;
  items: EventSubItem[];
}

export interface EventSubItem {
  imageSource: string;
  title: string;
  subTitle: string;
}

@Component({
  selector: 'app-event-tab',
  templateUrl: './event-tab.component.html',
  styleUrls: ['./event-tab.component.scss']
})
export class EventTabComponent implements OnInit {
  eventItems: EventItem[] = [
    {
      title: 'SAMSTAG, 06.01.2018',
      items: [
        {
          title: '10:00 Uhr',
          subTitle: 'Einlass & kleines Frühstück',
          imageSource: 'assets/persons/telis.jpg'
        },
        {
          title: '10:00 Uhr',
          subTitle: 'Messerundgang',
          imageSource: 'assets/persons/telis.jpg'
        },
        {
          title: '11:30 Uhr',
          subTitle: 'Mittagessen & Messebetrieb',
          imageSource: 'assets/persons/telis.jpg'
        },
        {
          title: '12:30 Uhr',
          subTitle: 'Einlass Saal',
          imageSource: 'assets/persons/telis.jpg'
        },
        {
          title: '12:45 Uhr',
          subTitle: 'Begrüßung',
          imageSource: 'assets/persons/mpoell.jpg'
        },
        {
          title: '14:00 Uhr',
          subTitle: 'Pause und Messebetrieb',
          imageSource: 'assets/persons/telis.jpg'
        },
        {
          title: '15:00 Uhr',
          subTitle: 'Digitalisierung',
          imageSource: 'assets/persons/kbolz.jpg'
        },
        {
          title: '15:00 Uhr',
          subTitle: 'Digitalisierung',
          imageSource: 'assets/persons/egeffroy.jpg'
        },
        {
          title: '16:00 Uhr',
          subTitle: 'Pause und Messebetrieb',
          imageSource: 'assets/persons/telis.jpg'
        },
        {
          title: '16:50 Uhr',
          subTitle: 'Digitalisierung',
          imageSource: 'assets/persons/lritzmann.jpg'
        }
      ]
    },
    {
      title: 'ABENDVERANSTALTUNG',
      items: [
        {
          imageSource: 'assets/persons/kbolz.jpg',
          subTitle: 'Einlass',
          title: '20:00 Uhr',
        },
        {
          imageSource: 'assets/persons/telis.jpg',
          subTitle: 'Auszeichnungen und Ehrungen',
          title: '21:30 Uhr'
        },
        {
          imageSource: 'assets/persons/telis.jpg',
          subTitle: 'Verlosung „TELIS Jackpot“',
          title: '23:00 Uhr'
        }
      ]
    }
  ];

  constructor() {}

  ngOnInit() {}
}
