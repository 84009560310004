import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Corporations } from '../shared/static/corporations';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss']
})
export class PartnersComponent implements OnInit {
  aplphabet = Array.from('ABCDEFGHIJKLMNOPQRSTUVWXYZ');
  searchBarFormGroup: FormGroup;
  corporations = Corporations;

  constructor(public location: Location, private formBuilder: FormBuilder) {
    this.searchBarFormGroup = this.formBuilder.group({
      searchTerm: []
    });
    this.searchBarFormGroup
      .get('searchTerm')
      .valueChanges.subscribe(
        res =>
          (this.corporations = Corporations.filter(corporation => corporation.name.includes(res)))
      );
  }

  ngOnInit() {}
}
