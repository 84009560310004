export const Schedules: any = [
  {
    headline: 'SAMSTAG, 12.09.2020',
    sections: [
      {
        events: [
          {
            time: '11:00',
            speaker: 'Block 1\n\n Rückblick,\n\n Aktuelles aus der Konzernzentrale,\n\n Auszeichnungen, Innovationen 2020,\n\n Digitale Kundenpräsentation\n\n und Videoberatung'
          },
          {
            time: 'ca. 12:20',
            speaker: 'Pause'
          },
          {
            time: '13:00',
            speaker: 'Block 2\n\n DMF im Konzern, Vision 2025,\n\n Umsetzung Jahresendgeschäft 2020'
          },
          {
            time: 'ca. 14:20',
            speaker: 'Pause',
          },
          {
            time: '14:45',
            speaker: 'Block 3\n\n Verlosung Sachpreiswettbewerb,\n\n Chancen im Jahresendgeschäft,\n\n Neuer Wettbewerb',
          },
          {
            time: 'ca. 16:00',
            speaker: 'Ende'
          }
        ],
      }
    ]
  }
];
