import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './pages/shared/components/footer/footer.component';
import { HeaderComponent } from './pages/shared/components/header/header.component';
import { TicketDialogComponent } from './pages/shared/components/ticket-dialog/ticket-dialog.component';
import { MaterialModule } from './material/material.module';
import { MainComponent, SafePipe } from './pages/main/main.component';
import { EventTabComponent } from './pages/main/tabs/event-tab/event-tab.component';
import { HighlightsTabComponent } from './pages/main/tabs/highlights-tab/highlights-tab.component';
import { MesseTabComponent } from './pages/main/tabs/messe-tab/messe-tab.component';
import { WelcomeTabComponent } from './pages/main/tabs/welcome-tab/welcome-tab.component';
import { EventComponent } from './pages/event/event.component';
import { FairComponent } from './pages/fair/fair.component';
import { ImpressumComponent } from './pages/impressum/impressum.component';
import { LoginComponent } from './pages/login/login.component';
import { PartnersComponent } from './pages/partners/partners.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptorService } from './services/jwt-interceptor.service';
import { ErrorInterceptorService } from './services/error-interceptor.service';
import { NgxBarcodeModule } from 'ngx-barcode';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthenticationLibModule } from '../../authentication-lib/src/lib/authentication-lib.module';
import { PopupsLibModule } from '../../popups-lib/src/lib/popups-lib.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';






import { SimpleSmoothScrollModule } from 'ng2-simple-smooth-scroll';



@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    TicketDialogComponent,
    MainComponent,
    EventTabComponent,
    HighlightsTabComponent,
    MesseTabComponent,
    WelcomeTabComponent,
    EventComponent,
    FairComponent,
    ImpressumComponent,
    LoginComponent,
    PartnersComponent,
    PrivacyComponent,
    SafePipe
  ],
  entryComponents: [TicketDialogComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    AppRoutingModule,
    NgxBarcodeModule,
    FormsModule,
    ReactiveFormsModule,
    AuthenticationLibModule,
    PopupsLibModule,
    SimpleSmoothScrollModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptorService, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
