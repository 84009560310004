import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { switchMap, tap, catchError, map } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material';
import { SnackbarService } from '../../../popups-lib/src/lib/services/snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class TeilnehmerService {
  teilnehmernummer$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(private httpClient: HttpClient, private snackbarService: SnackbarService) {
    this.teilnehmernummer$.next(localStorage.getItem('teilnehmernummer'));
  }

  storeTeilnehmernummer(teilnehmernummer: string): boolean {
    if (!!teilnehmernummer) {
      this.teilnehmernummer$.next(teilnehmernummer);
      localStorage.setItem('teilnehmernummer', teilnehmernummer);
    } else {
      this.teilnehmernummer$.next(null);
      localStorage.removeItem('teilnehmernummer');
    }
    return true;
  }

  requestTeilnehmernummer(): Observable<boolean> {
    return this.httpClient.get('https://dfa.gwvs.de/api/teilnehmernr').pipe(
      catchError(err => {
        // handle Error
        return of(null);
      }),
      map((result: any) => {
        return result.TEILNEHMERNR || null;
      }),
      tap((result: any) => {
        console.log(result);
        if (!result) {
          this.snackbarService.show('Hinweis: Keine Teilnehmernummer auf Server gefunden.');
        }
      }),
      switchMap((teilnehmernummer: string) => of(this.storeTeilnehmernummer(teilnehmernummer)))
    );
  }

  getTeilnehmernummer(): Observable<string> {
    return this.teilnehmernummer$.asObservable();
  }
}
