import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

export class rect {
  width;
  height;
  x;
  y;
  constructor(width, height, x, y) {
    this.width = width;
    this.height = height;
    this.x = x;
    this.y = y;
  }
}

export class triangle {}

@Component({
  selector: 'app-messe-tab',
  templateUrl: './messe-tab.component.html',
  styleUrls: ['./messe-tab.component.scss']
})
export class MesseTabComponent implements OnInit {
  @ViewChild('myCanvas', {read: true, static: false}) canvasRef: ElementRef;

  areaPlan = {
    ground: { width: 500, height: 500 },
    booths: [
      new rect(30, 30, 15, 15),
      new rect(30, 30, 75, 15),
      new rect(30, 30, 125, 15),
      new rect(60, 30, 75, 70)
    ]
  };

  constructor() {}

  ngOnInit(): void {
    let ctx: CanvasRenderingContext2D = this.canvasRef.nativeElement.getContext('2d');

    // Draw the clip path that will mask everything else
    // that we'll draw later.
    // Red rectangle
    ctx.beginPath();
    ctx.lineWidth = 6;
    ctx.strokeStyle = 'red';
    ctx.rect(5, 5, 290, 140);
    ctx.stroke();
  }

  logClick(element): void {}
}
