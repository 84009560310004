import { Component, OnInit, PipeTransform, Pipe } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { LoginService } from '../../../../authentication-lib/src/lib/services/login.service';
import { TokenService } from '../../../../authentication-lib/src/lib/services/token.service';
import { Router } from '@angular/router';
import { TicketDialogComponent } from '../shared/components/ticket-dialog/ticket-dialog.component';
import { Locations } from '../shared/static/locations';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({name: 'safe'})
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  links = ['Welcome', 'Event', 'Third'];
  activeLink = this.links[0];
  title = 'jat-planer';
  year = 2019;
  locations = Locations;

  currentTime = 0;
  saturday_1800 = 1547312400000;
  sunday_1600 = 1547391600000;

  constructor(
    private loginService: LoginService,
    private tokenService: TokenService,
    private router: Router,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    public sanitizer: DomSanitizer
  ) {
    this.refreshTime();
  }

  ngOnInit() {}

  notYet(time: number): boolean {
    return time - new Date().getTime() > 0;
  }

  refreshTime(time?): void {
    this.currentTime = new Date().getTime();
    if (time && this.notYet(time)) {
      this.openSnackBar('Dieser Bereich ist zur Zeit noch nicht verfügbar');
    }
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, null, {
      duration: 1500
    });
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(TicketDialogComponent, {
      width: '250px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
    });
  }

  logout(): void {
    this.tokenService.storeAccessToken(null);
    this.tokenService.storeRefreshToken(null);
    this.loginService.setAuthState(false);
    this.router.navigate(['/login']);
  }
}
