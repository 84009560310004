import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './pages/main/main.component';
import { EventComponent } from './pages/event/event.component';
import { FairComponent } from './pages/fair/fair.component';
import { PartnersComponent } from './pages/partners/partners.component';
import { LoginComponent } from './pages/login/login.component';
import { ImpressumComponent } from './pages/impressum/impressum.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';


const routes: Routes = [
  { path: '', redirectTo: '/', pathMatch: 'full' },
  { path: '', component: MainComponent },
  { path: 'event', component: EventComponent},
  { path: 'impressum', component: ImpressumComponent },
  { path: 'datenschutz', component: PrivacyComponent },
  { path: '**', component: MainComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
