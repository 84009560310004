import { BrowserModule } from '@angular/platform-browser';
/*
'Altersvorsorge & Biometrie',
'Gesundheitsvorsorge',
'Finanzierung & Bausparen',
'Komposit',
'TELIS'
 */
export const Corporations = [
  {
    name: 'Allianz',
    image: 'allianz.png',
    standNumber: 8,
    subject: ['Alle', 'Altersvorsorge & Biometrie', 'Gesundheitsvorsorge', 'Komposit']
  },
  {
    name: 'Alte Leipziger',
    image: 'alte_leipziger_bauspar.png',
    standNumber: 31,
    subject: ['Alle', 'Komposit', 'Altersvorsorge & Biometrie', 'Finanzierung & Bausparen']
  },
  {
    name: 'ARAG',
    image: 'arag.png',
    standNumber: 19,
    subject: ['Alle', 'Gesundheitsvorsorge', 'Komposit']
  },
  {
    name: 'AXA',
    image: 'axa.png',
    standNumber: 9,
    subject: ['Alle', 'Altersvorsorge & Biometrie', 'Komposit', 'Gesundheitsvorsorge']
  },
  {
    name: 'BKK Mobil Oil',
    image: 'mobil.png',
    standNumber: 14,
    subject: ['Alle', 'Gesundheitsvorsorge']
  },
  {
    name: 'Canada Life',
    image: 'canadalife.png',
    standNumber: 10,
    subject: ['Alle', 'Altersvorsorge & Biometrie']
  },
  {
    name: 'Condor',
    image: 'condor.png',
    standNumber: 24,
    subject: ['Alle', 'Altersvorsorge & Biometrie']
  },
  {
    name: 'Continentale',
    image: 'continentale.png',
    standNumber: 5,
    subject: ['Alle', 'Altersvorsorge & Biometrie', 'Gesundheitsvorsorge', 'Komposit']
  },
  {
    name: 'DELA',
    image: 'dela.png',
    standNumber: 38,
    subject: ['Alle', 'Altersvorsorge & Biometrie']
  },
  {
    name: 'DEMA',
    image: 'dema.png',
    standNumber: 33,
    subject: ['Alle', 'Komposit']
  },
  {
    name: 'Deurag',
    image: 'deurag.png',
    standNumber: 16,
    subject: ['Alle', 'Komposit']
  },
  {
    name: 'Dialog',
    image: 'dialog.png',
    standNumber: 29,
    subject: ['Alle', 'Altersvorsorge & Biometrie', 'Komposit']
  },
  {
    name: 'Die Bayrische',
    image: 'diebayerische.png',
    standNumber: 30,
    subject: ['Alle', 'Altersvorsorge & Biometrie', 'Komposit']
  },
  {
    name: 'DKV',
    image: 'dkv.png',
    standNumber: 34,
    subject: ['Alle', 'Gesundheitsvorsorge']
  },
  {
    name: 'DMF Foto',
    image: 'dmf.png',
    standNumber: 42,
    subject: ['Alle', 'Deutsches Maklerforum']
  },
  {
    name: 'DMF Shop',
    image: 'dmf_shop.png',
    standNumber: 1,
    subject: ['Alle', 'Deutsches Maklerforum']
  },
  {
    name: 'ERGO',
    image: 'ergo.png',
    standNumber: 34,
    subject: ['Alle', 'Altersvorsorge & Biometrie', 'Komposit']
  },
  {
    name: 'Generali',
    image: 'generali.png',
    standNumber: 29,
    subject: ['Alle', 'Komposit']
  },
  {
    name: 'Gothaer',
    image: 'gothaer.png',
    standNumber: 12,
    subject: ['Alle', 'Altersvorsorge & Biometrie', 'Gesundheitsvorsorge', 'Komposit']
  },
  {
    name: 'Hallesche',
    image: 'hallesche.png',
    standNumber: 31,
    subject: ['Alle', 'Gesundheitsvorsorge']
  },
  {
    name: 'Hannoversche Leben',
    image: 'hannoversche.png',
    standNumber: 28,
    subject: ['Alle', 'Altersvorsorge & Biometrie']
  },
  {
    name: 'Hanse Merkur',
    image: 'hansemerkur.png',
    standNumber: 11,
    subject: ['Alle', 'Komposit', 'Altersvorsorge & Biometrie', 'Gesundheitsvorsorge']
  },
  {
    name: 'HDI',
    image: 'hdi.png',
    standNumber: 21,
    subject: ['Alle', 'Komposit', 'Altersvorsorge & Biometrie']
  },
  {
    name: 'HEK',
    image: 'hek.png',
    standNumber: 26,
    subject: ['Alle', 'Gesundheitsvorsorge']
  },
  {
    name: 'Ideal',
    image: 'ideal.png',
    standNumber: 27,
    subject: ['Alle', 'Altersvorsorge & Biometrie']
  },
  {
    name: 'Inno Assekuranz',
    image: 'innoassekuranz.png',
    standNumber: 20,
    subject: ['Alle', 'Komposit']
  },
  {
    name: 'Inter',
    image: 'inter.png',
    standNumber: 41,
    subject: ['Alle', 'Gesundheitsvorsorge', 'Komposit']
  },
  {
    name: 'Kravag',
    image: 'kravag.png',
    standNumber: 24,
    subject: ['Alle', 'Komposit']
  },
  {
    name: 'LV 1871',
    image: 'lv1871.png',
    standNumber: 39,
    subject: ['Alle', 'Altersvorsorge & Biometrie']
  },
  {
    name: 'Monuta',
    image: 'monuta.png',
    standNumber: 40,
    subject: ['Alle', 'Altersvorsorge & Biometrie']
  },
  {
    name: 'Münchener Verein',
    image: 'muenchenerverein.png',
    standNumber: 18,
    subject: ['Alle', 'Altersvorsorge & Biometrie', 'Gesundheitsvorsorge']
  },
  {
    name: 'Nürnberger',
    image: 'nuernberger.png',
    standNumber: 6,
    subject: ['Alle', 'Altersvorsorge & Biometrie', 'Gesundheitsvorsorge', 'Komposit']
  },
  {
    name: 'Ostangler Brandgilde',
    image: 'ostangler.png',
    standNumber: 22,
    subject: ['Alle', 'Komposit']
  },
  {
    name: 'R+V',
    image: 'ruv.png',
    standNumber: 24,
    subject: ['Alle', 'Gesundheitsvorsorge']
  },
  {
    name: 'Rhion digital',
    image: 'rhion.png',
    standNumber: 23,
    subject: ['Alle', 'Komposit']
  },
  {
    name: 'Salus BKK',
    image: 'salus_bkk.png',
    standNumber: 25,
    subject: ['Alle', 'Gesundheitsvorsorge']
  },
  {
    name: 'Signal Iduna',
    image: 'signaliduna.png',
    standNumber: 35,
    subject: ['Alle', 'Altersvorsorge & Biometrie', 'Gesundheitsvorsorge', 'Komposit']
  },
  {
    name: 'Standard Life',
    image: 'standardlife.png',
    standNumber: 13,
    subject: ['Alle', 'Altersvorsorge & Biometrie']
  },
  {
    name: 'Stuttgarter',
    image: 'diestuttgarter.png',
    standNumber: 17,
    subject: ['Alle', 'Altersvorsorge & Biometrie', 'Komposit']
  },
  {
    name: 'Swiss Life',
    image: 'swisslife.png',
    standNumber: 4,
    subject: ['Alle', 'Altersvorsorge & Biometrie']
  },
  {
    name: 'Universa',
    image: 'universa.png',
    standNumber: 36,
    subject: ['Alle', 'Komposit', 'Altersvorsorge & Biometrie', 'Gesundheitsvorsorge']
  },
  {
    name: 'Verti',
    image: 'verti.png',
    standNumber: 15,
    subject: ['Alle', 'Komposit']
  },
  {
    name: 'VK Maklermanagement',
    image: 'versicherungskammer.png',
    standNumber: 32,
    subject: ['Alle', 'Gesundheitsvorsorge']
  },
  {
    name: 'VWB',
    image: 'volkswohlbund.png',
    standNumber: 7,
    subject: ['Alle', 'Altersvorsorge & Biometrie', 'Komposit']
  },
  {
    name: 'Wüstenrot',
    image: 'wuestenrot.png',
    standNumber: 37,
    subject: ['Alle', 'Finanzierung & Bausparen']
  },
  {
    name: 'WWK',
    image: 'wwk.png',
    standNumber: 3,
    subject: ['Alle', 'Altersvorsorge & Biometrie']
  },
  {
    name: 'Zurich',
    image: 'zurich.png',
    standNumber: 2,
    subject: ['Alle', 'Altersvorsorge & Biometrie', 'Komposit']
  },
];
