import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TeilnehmerService } from '../../../../services/teilnehmer.service';
import { LoginService } from '../../../../../../authentication-lib/src/lib/services/login.service';
import { TokenService } from '../../../../../../authentication-lib/src/lib/services/token.service';

@Component({
  selector: 'app-ticket-dialog',
  templateUrl: './ticket-dialog.component.html',
  styleUrls: ['./ticket-dialog.component.scss']
})
export class TicketDialogComponent implements OnDestroy {
  destroyed$ = new Subject();
  teilnehmernummer$;
  loading = false;
  auhtenticated$: Observable<boolean>;

  constructor(
    private loginService: LoginService,
    private tokenService: TokenService,
    public dialogRef: MatDialogRef<TicketDialogComponent>,
    private teilnehmerService: TeilnehmerService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.teilnehmernummer$ = this.teilnehmerService.getTeilnehmernummer();
    this.auhtenticated$ = this.loginService.getAuthState();
  }

  requestTeilnehmernummer() {
    this.loading = true;
    this.teilnehmerService
      .requestTeilnehmernummer()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.loading = false;
      });
  }

  logout(): void {
    this.tokenService.storeAccessToken(null);
    this.tokenService.storeRefreshToken(null);
    this.loginService.setAuthState(false);
    this.dismiss();
  }

  dismiss() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  getBarcode(employeeNumber) {
    return employeeNumber + String.fromCharCode(this.getChar(this.crossSum(employeeNumber) % 43));
  }

  crossSum(unformatedCode: string): number {
    unformatedCode = String(unformatedCode);
    return unformatedCode
      .split('')
      .map(char => parseInt(char))
      .reduce((prev, curr) => prev + curr, 0);
  }

  getChar(c: number) {
    if (c >= 0 && c <= 9) {
      return c + 48;
    } else if (c >= 10 && c <= 35) {
      return c + 55;
    } else if (c == 36) {
      return 45;
    } else if (c == 37) {
      return 46;
    } else if (c == 38) {
      return 32;
    } else if (c == 39) {
      return 36;
    } else if (c == 40) {
      return 47;
    } else if (c == 41) {
      return 43;
    } else if (c == 42) {
      return 37;
    } else {
    }
  }
}
