import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptorService implements HttpInterceptor {
  constructor(private httpClient: HttpClient) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next.handle(request).pipe(
      catchError(err => {
        if (err.status === 401) {
          console.log('Error in ErrorInterceptor: ', err);
          if (!this.accessTokenRequest(request)) {
            this.httpClient
              .get('https://dfa.gwvs.de/auth/token')
              .subscribe(result => console.log(result));
          }
          // auto logout if 401 response returned from api
          // this.authenticationService.logout();
          // location.reload(true);
        }
        return throwError(err);
      })
    );
  }

  accessTokenRequest(request: HttpRequest<any>): boolean {
    return request.url.startsWith('https://dfa.gwvs.de/auth/');
  }
}
