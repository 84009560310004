import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, FormControl} from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Corporations } from '../shared/static/corporations';

@Component({
  selector: 'app-fair',
  templateUrl: './fair.component.html',
  styleUrls: ['./fair.component.scss']
})
export class FairComponent implements OnInit {
  searchBarFormGroup: FormGroup;
  corporations = Corporations;
  filteredCorporations = Corporations;
  focus: boolean;
  selectedBooth: number = 0;

  subjects = [
    'Altersvorsorge & Biometrie',
    'Gesundheitsvorsorge',
    'Finanzierung & Bausparen',
    'Komposit',
    'Deutsches Maklerforum'
  ];

  constructor(
    public location: Location,
    private formBuilder: FormBuilder,
    public dialog: MatDialog
  ) {
    this.searchBarFormGroup = this.formBuilder.group({
      searchTerm: []
    });
    this.searchBarFormGroup.get('searchTerm').valueChanges.subscribe(res => {
      res = res.toLocaleLowerCase().trim();
      if (!res) {
        this.selectedBooth = 0;
      } else {
        this.filteredCorporations = Corporations.filter(
          (corporation: any) =>
            corporation.subject.some(subj => this.levenshtein(subj.toLocaleLowerCase(), res) < 3) ||
            corporation.subject.some(subj => subj.toLocaleLowerCase().includes(res)) ||
            this.levenshtein(corporation.name.toLocaleLowerCase(), res) < 3 ||
            corporation.name.toLocaleLowerCase().includes(res)
        );
      }
    });
  }

  corporationClicked(corporation: any) {
    this.searchBarFormGroup.get('searchTerm').setValue(corporation.name);
    this.selectedBooth = corporation.standNumber;
  }

  getCategorizedCorporations(subject, filtered = true) {
    console.log(filtered);
    return filtered
      ? this.filteredCorporations.filter(corporation =>
          corporation.subject.some(subj => subj === subject)
        )
      : this.corporations.filter(corporation => corporation.subject.some(subj => subj == subject));
  }

  onBlur() {
    setTimeout(() => {
      this.setFocus(false);
    }, 100);
  }

  setFocus(focus) {
    this.focus = focus;
  }

  clearSearchBar() {
    this.searchBarFormGroup.get('searchTerm').setValue('');
  }

  ngOnInit() {}

  levenshtein(a, b) {
    var tmp;
    if (a.length === 0) {
      return b.length;
    }
    if (b.length === 0) {
      return a.length;
    }
    if (a.length > b.length) {
      tmp = a;
      a = b;
      b = tmp;
    }

    var i,
      j,
      res,
      alen = a.length,
      blen = b.length,
      row = Array(alen);
    for (i = 0; i <= alen; i++) {
      row[i] = i;
    }

    for (i = 1; i <= blen; i++) {
      res = i;
      for (j = 1; j <= alen; j++) {
        tmp = row[j - 1];
        row[j - 1] = res;
        res = b[i - 1] === a[j - 1] ? tmp : Math.min(tmp + 1, Math.min(res + 1, row[j] + 1));
      }
    }
    return res;
  }
}
