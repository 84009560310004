import { Injectable, OnDestroy } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { TokenService } from '../../../authentication-lib/src/lib/services/token.service';
import { takeUntil } from 'rxjs/operators';
// import { HttpInterceptingHandler } from '@angular/common/http/src/module';

@Injectable({
  providedIn: 'root'
})
export class JwtInterceptorService implements OnDestroy, HttpInterceptor {
  token: string;
  refreshToken: string;
  destroyed$: Subject<any> = new Subject<any>();

  constructor(private tokenService: TokenService) {
    this.tokenService
      .getAccessToken()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(token => (this.token = token));
    this.tokenService
      .getRefreshToken()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(token => (this.refreshToken = token));
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.token) {
      console.log('====>>>', this.token);
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.accessTokenRequest ? this.refreshToken : this.token}`
        }
      });
    }
    console.log('request', request);
    return next.handle(request);
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  accessTokenRequest(request: HttpRequest<any>): boolean {
    return request.url.startsWith('https://dfa.gwvs.de/auth/');
  }
}
