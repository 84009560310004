export const Locations: any = [
  {
    title: 'Maklerforum der Vertriebsdirektion Kaufmann',
    subtitle: 'in Chemnitz',
    location: 'Ballhaus',
    address: 'Frankenberger Str. 31, 09131 Chemnitz',
    // tslint:disable-next-line: max-line-length
    iframe: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2519.0069548056663!2d12.942183615745854!3d50.84955547953242!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a74691ad0aa9b1%3A0x9f34c0d23cd7c570!2sFrankenberger%20Str.%2031%2C%2009131%20Chemnitz!5e0!3m2!1sde!2sde!4v1598952614352!5m2!1sde!2sde'
  },
  {
    title: 'Maklerforum der Vertriebsdirektion Celikten',
    subtitle: 'in Seeheim-Jugenheim',
    location: 'Lufthansa Seeheim',
    address: 'Lufthansa-Ring 1, 64342 Seeheim-Jugenheim',
    // tslint:disable-next-line: max-line-length
    iframe: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2577.3732436705313!2d8.650191215706515!3d49.76023567938607!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47bd7cb6f8e050c9%3A0xe09446ed91e36742!2sLufthansaring%201%2C%2064342%20Seeheim-Jugenheim!5e0!3m2!1sde!2sde!4v1598958182655!5m2!1sde!2sde'
  },
  {
    title: 'Maklerforum der Direktion Schmid',
    subtitle: 'in Regensburg',
    location: 'Deutsches Maklerforum Konzernzentrale',
    address: 'Ziegetsdorfer Straße 118, 93051 Regensburg',
    // tslint:disable-next-line: max-line-length
    iframe: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2617.032404499956!2d12.057547115679746!3d49.009968479302714!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479fc1e54674f131%3A0xdade72f3c4dd552a!2sZiegetsdorfer%20Str.%20118%2C%2093051%20Regensburg!5e0!3m2!1sde!2sde!4v1598958237642!5m2!1sde!2sde'
  },
  {
    title: 'Maklerforum der Geschäftsstelle Mahr',
    subtitle: 'in Seeheim-Jugenheim',
    location: 'Lufthansa Seeheim',
    address: 'Lufthansa-Ring 1, 64342 Seeheim-Jugenheim',
    // tslint:disable-next-line: max-line-length
    iframe: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2577.3732436705313!2d8.650191215706515!3d49.76023567938607!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47bd7cb6f8e050c9%3A0xe09446ed91e36742!2sLufthansaring%201%2C%2064342%20Seeheim-Jugenheim!5e0!3m2!1sde!2sde!4v1598958182655!5m2!1sde!2sde'
  },
  {
    title: 'Maklerforum der Regionaldirektion Schwatz',
    subtitle: 'in Chemnitz',
    location: 'Ballhaus',
    address: 'Frankenberger Str. 31, 09131 Chemnitz',
    // tslint:disable-next-line: max-line-length
    iframe: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2519.0069548056663!2d12.942183615745854!3d50.84955547953242!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a74691ad0aa9b1%3A0x9f34c0d23cd7c570!2sFrankenberger%20Str.%2031%2C%2009131%20Chemnitz!5e0!3m2!1sde!2sde!4v1598952614352!5m2!1sde!2sde'
  },
  {
    title: 'Maklerforum der Regionaldirektion Rittker',
    subtitle: 'in Regensburg',
    location: 'Deutsches Maklerforum Konzernzentrale',
    address: 'Ziegetsdorfer Straße 118, 93051 Regensburg',
    // tslint:disable-next-line: max-line-length
    iframe: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2617.032404499956!2d12.057547115679746!3d49.009968479302714!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479fc1e54674f131%3A0xdade72f3c4dd552a!2sZiegetsdorfer%20Str.%20118%2C%2093051%20Regensburg!5e0!3m2!1sde!2sde!4v1598958237642!5m2!1sde!2sde'
  },
  {
    title: 'Maklerforum der Regionaldirektion Boelk',
    subtitle: 'in Berlin',
    location: 'Deutsches Maklerforum Geschäftsstelle Berlin',
    address: 'Tabberstraße 14, 12459 Berlin',
    // tslint:disable-next-line: max-line-length
    iframe: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2430.616306663602!2d13.501431515805518!3d52.46797597980438!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a84f3537bf414b%3A0x7c54be9b58897ba2!2sTabbertstra%C3%9Fe%2014%2C%2012459%20Berlin!5e0!3m2!1sde!2sde!4v1598958386254!5m2!1sde!2sde'
  },
  {
    title: 'Maklerforum der Geschäftsstelle Steinhoff',
    subtitle: 'in Dorsten',
    location: 'Deutsches Maklerforum Geschäftsstelle Dorsten',
    address: 'Am Brauturm 6, 46286 Dorsten',
    // tslint:disable-next-line: max-line-length
    iframe: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2471.909250793411!2d7.0098756157776725!3d51.71640277967001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b8f21b7f7b6c71%3A0x50a434f661b6c747!2sAm%20Brauturm%206%2C%2046286%20Dorsten!5e0!3m2!1sde!2sde!4v1598958423282!5m2!1sde!2sde'
  },
  {
    title: 'Maklerforum der Geschäftsstelle Stotz',
    subtitle: 'in Köln',
    location: 'Büro der IDA FINANZ GmbH',
    address: 'Frankfurter Str. 705, 51107 Köln',
    // tslint:disable-next-line: max-line-length
    iframe: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2514.6645857147496!2d7.039045115748825!3d50.92992137954436!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47bf26541b9ea21f%3A0xdb12f55b6a4ddb0c!2sFrankfurter%20Str.%20705%2C%2051107%20K%C3%B6ln!5e0!3m2!1sde!2sde!4v1598958456878!5m2!1sde!2sde'
  },
  {
    title: 'Maklerforum DMF Direkt',
    subtitle: 'in Regensburg',
    location: 'Deutsches Maklerforum Konzernzentrale',
    address: 'Ziegetsdorfer Straße 118, 93051 Regensburg',
   // tslint:disable-next-line: max-line-length
   iframe: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2617.032404499956!2d12.057547115679746!3d49.009968479302714!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479fc1e54674f131%3A0xdade72f3c4dd552a!2sZiegetsdorfer%20Str.%20118%2C%2093051%20Regensburg!5e0!3m2!1sde!2sde!4v1598958237642!5m2!1sde!2sde'
  }
];
