import { Injectable, OnDestroy } from '@angular/core';
import { Observable, of, BehaviorSubject, Subject } from 'rxjs';
import { catchError, takeUntil, take, map, filter, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { TokenService } from './token.service';

export enum urls {
  LOGIN = 'https://dfa.gwvs.de/auth/login'
}

@Injectable({
  providedIn: 'root'
})
export class LoginService implements OnDestroy {
  destroyed$: Subject<any> = new Subject<any>();
  authenticated$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private httpClient: HttpClient, private tokenService: TokenService) {
    this.loginWithExistingToken();
  }

  loginWithUsernameAndPassword(credentials): void {
    this.httpClient
      .post(urls.LOGIN, credentials)
      .pipe(
        takeUntil(this.destroyed$),
        catchError(err => {
          console.log('ERROR: ', err);
          return of(false);
        })
      )
      .subscribe((result: any) => {
        if (!!result) {
          this.tokenService.storeAccessToken(result.token);
          this.tokenService.storeRefreshToken(result.refreshToken);
        }
        this.setAuthState(!!result);
      });
  }

  loginWithExistingToken(): void {
    this.tokenService
      .getAccessToken()
      .pipe(
        tap(token => console.log('loginWithExistingToken()', token)),
        filter(token => !!token)
      )
      .subscribe(() => this.setAuthState(true));
  }

  setAuthState(state: boolean): void {
    this.authenticated$.next(state);
  }

  getAuthState(): Observable<boolean> {
    return this.authenticated$.asObservable();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }
}
