import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Schedules } from '../shared/static/schedules';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss']
})
export class EventComponent implements OnInit {
  schedules = Schedules;

  constructor(public location: Location) {}

  ngOnInit() {}

}
